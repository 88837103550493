import {
  json,
  Link,
  Outlet,
  useLoaderData,
  useLocation,
} from '@remix-run/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { honeypot } from '~/lib/server/honeypot.server';
import logo from '~/images/logo-wide.svg';
import { Button } from '@cardo/ui';

export async function loader() {
  return json({ honeypotInputProps: honeypot.getInputProps() });
}

export default function AuthLayout() {
  const { honeypotInputProps } = useLoaderData<typeof loader>();

  const publicAppUrl =
    typeof window !== 'undefined'
      ? window.ENV.PUBLIC_APP_URL
      : process.env.PUBLIC_APP_URL;

  const location = useLocation();

  const isOnLoginPage = location.pathname === '/login';
  const isOnSignupPage = location.pathname === '/signup';

  return (
    <div className="w-screen h-screen overflow-x-hidden flex flex-col">
      <header className="border px-2 sm:px-4 py-3">
        <nav className="flex items-center justify-between">
          <Link to={publicAppUrl ?? '/'}>
            <img src={logo} alt="cardonomics logo" />
          </Link>
          <div className="flex items-center gap-2">
            {!isOnLoginPage && (
              <Link to="/login">
                <Button
                  ghost
                  large
                  type="button"
                  className="text-gray-500 cursor-pointer"
                >
                  Log In
                </Button>
              </Link>
            )}
            {!isOnSignupPage && (
              <Link to="/signup">
                <Button primary large type="button" className="cursor-pointer">
                  Sign Up
                </Button>
              </Link>
            )}
          </div>
        </nav>
      </header>
      <main className="flex flex-col flex-grow px-4 sm:px-8 py-8 sm:py-14">
        <HoneypotProvider {...honeypotInputProps}>
          <Outlet />
        </HoneypotProvider>
      </main>
    </div>
  );
}
